
declare global {
    interface Window { sdkData: any; sendEventToGLGPoker: any; integrationMode: any }
}

let firstTime = false;
let sdkRetryCount = 0;

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());


/* {
"event":"player_exchange_auth",
"params":{
"name":"Test4",
"session_key":"",
"state":"","timestamp":1649913636,
"client_id":"spartan-aio-exchange",
"email":"",
"country":"India",
"mobile_number":"",
"user_id":"155",
"hash":"4172917d02b569f46b5fd5fed6e51bf8bf74c23e791868906a0e1123721ae064"
}} */

if (params && params.integration_mode) {
    checkForSdkData();
} else {
    if (params && params.hash) {
        constructWebExchangeData();
    }
}

/* const sdkData = {
    header: {
        command_id: 'INITILIAZE_EXTERNAL_POKER',
        command_name: 'INITILIAZE_EXTERNAL_POKER'
    },
    payload: input
}
window.postMessage(sdkData, '*') */


function constructWebExchangeData() {
    window.sdkData = {
        integrationMode: "SDK_WITH_EXCHANGE", pokerInitialData: {
            data: {
                "name": params.name,
                "session_key": params.session_key,
                "state": (params.state === "" || params.state === null || params.state === "null" || !params.state) ? null : params.state,
                "timestamp": params.timestamp,
                "client_id": params.client_id,
                "email": (params.email === "" || params.email === null || params.email === "null" || !params.email) ? null : params.email,
                "country": params.country,
                "mobile_number": (params.mobile_number === "" || params.mobile_number === null || params.mobile_number === "null" || !params.mobile_number) ? null : params.mobile_number,
                "user_id": params.user_id,
                "hash": params.hash,
            }
        }
    }

    bootstrap();

    console.log("web exchange params", JSON.stringify(params), window.sdkData)
}



function checkForSdkData() {
    if (window.sdkData) {

        window.sdkData = { ...window.sdkData, integrationMode: "SDK_WITH_EXCHANGE" }

        bootstrap();

        console.log("params", JSON.stringify(params), params.integration_mode, window.sdkData)
    } else {
        if (params && params.integration_mode) {
            if (sdkRetryCount < 5) {
                sdkRetryCount = sdkRetryCount + 1;
                console.log("waiting for apk data - retry count", sdkRetryCount);
                setTimeout(checkForSdkData, 200);
            } else {
                console.log("No APK Data . Trigger APK Refresh WebView Command");
                window.parent.postMessage('refreshGame', '*');
            }

        }
    }
}


document.addEventListener("visibilitychange", () => {
    if (params && params.integration_mode) {
        if (document.visibilityState !== "hidden") {
            if (!window.sdkData) {
                console.log("No APK Data . Trigger APK Refresh WebView Command on App Focus");
                window.parent.postMessage('refreshGame', '*');
            }
        }
    }
}, false);

/* const processSubModuleCommands = (data: any) => {
    console.log("processSubModuleCommands", JSON.stringify(data))

    if (data === null || data === undefined)
        return;

    let res: any = data
    try {
        res = JSON.parse(data)
    }
    catch (e) {
        console.error('error while parsing post message', e)
    }

    switch (res?.header?.command_id) {
        case 'LOBBY_LOAD_COMPLETED':
            if (window.sdkData && window.sdkData.pokerInitialData)
                window.postMessage(JSON.stringify(window.sdkData.pokerInitialData), '*')
            break;
    }
}

window.addEventListener('message', processSubModuleCommands) */

const processSubModuleCommands = (e: any) => {
    console.log("processSubModuleCommands", JSON.stringify(e.detail))

    if (e.detail === null || e.detail === undefined)
        return;

    let res: any = e.detail;
    /* try {
        res = JSON.parse(e.detail.data)
    }
    catch (e) {
        console.log('error while parsing post message', e)
    } */

    switch (res?.header?.command_id) {
        case 'LOBBY_LOAD_COMPLETED':
            if (window.sdkData && window.sdkData.pokerInitialData) {
                let parsedData: any = {}
                try {
                    if (typeof window.sdkData.pokerInitialData === "string") {
                        parsedData = JSON.parse(window.sdkData.pokerInitialData)
                    } else {
                        if (typeof window.sdkData.pokerInitialData === "object") {
                            parsedData = JSON.parse(JSON.stringify(window.sdkData.pokerInitialData));
                        }
                    }

                }
                catch (e) {
                    console.log("parsing sdk data is crashing", e)
                }
                const reqData = {
                    header: {
                        command_id: 'INITILIAZE_EXTERNAL_POKER',
                        command_name: 'INITILIAZE_EXTERNAL_POKER'
                    },
                    payload: parsedData.data
                }

                console.log("sdk data", JSON.stringify(reqData))

                // pokerChannel.postMessage(reqData)

                const containerToLobbyEvent = new CustomEvent('containerToLobbyEvent', { detail: reqData })


                document.dispatchEvent(containerToLobbyEvent)
            }
            break;
    }
}



// const pokerChannel = new BroadcastChannel('pokerChannel');

// pokerChannel.onmessage = processSubModuleCommands


document.addEventListener("lobbyToContainerEvent", processSubModuleCommands)

if ((!params.integration_mode || !params.integration_mode.includes("SDK_WITH_EXCHANGE") && !params.hash)) {
        bootstrap();
}

function isMobile() {
    var check = false;
    (function(a){
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) 
        check = true;
    })(navigator.userAgent||navigator.vendor);
    return check;
  };

function bootstrap() {
    if (!firstTime) {
        firstTime = true;
        (isMobile()) ? import("./bootstrap") : import("./bootstrapOnlyForLobby") 
    }
}

let startTime: any;
let endTime: any;
(window as any).pokerFullscreen = () => {
    if (isMobile()) {
      var element: any = document.body;
      // Supports most browsers and their versions.
      var requestMethod =
        element.requestFullscreen ||
        element.webkitRequestFullscreen ||
        element.mozRequestFullscreen ||
        element.msRequestFullscreen;

      if (requestMethod) {
        try {
          requestMethod.call(element);
        } catch (e) {
          console.log(e);
        }
        // Native full screen.
      }
    }
  }
document.addEventListener("visibilitychange", () => {

    if (document.visibilityState === "hidden") {
        startTime = Date.now();
    } else {
        (window as any).pokerFullscreen();
        endTime = Date.now();
        const deltaTime = endTime - startTime;
        if (deltaTime > 20 * 60 * 1000) {
            //window.location.reload();
        }
    }
}, false);

export { }